import React from "react"
import { Helmet } from "react-helmet"

import FontStyles from "../components/FontStyles"
import GlobalStyles from "../components/GlobalStyles"

import Privacy from "../components/Privacy"
import Footer from "../components/Footer"
import Header from "../components/Header"

const PrivacyPolicy = () => {
  return (
    <>
      <FontStyles />
      <GlobalStyles />
      <Helmet>
        <title>ASD Yuan - Privacy Policy</title>
        <meta name="description" content="Privacy Policy del sito." />
        <link
          rel="canonical"
          href="https://www.taichiyuan.it/privacy-policy/"
        />
      </Helmet>
      <Header />
      <Privacy />
      <Footer />
    </>
  )
}

export default PrivacyPolicy
