import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components/macro"
import { Link } from "gatsby"

import { QUERY, COLORS } from "../../constants"

const SpazioSociPush = () => {
  return (
    <Link to="/spazio-soci/home">
      <Wrapper>
        <Label>spazio soci</Label>
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled.a`
  position: absolute;
  top: -50px;
  right: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${COLORS.white};
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: 1.7rem 1rem;
  -webkit-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  -moz-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  -o-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  @media ${QUERY.verySmall} {
    width: 80px;
    height: 80px;
    padding: 1.4rem 1rem;
    right: calc(50% - 40px);
  }
`

const Label = styled.span`
  color: ${COLORS.red};
  text-transform: uppercase;
  font-weight: 600;
  font-size: calc(1.2 * var(--baseline-size));
  @media ${QUERY.verySmall} {
    font-size: calc(0.9 * var(--baseline-size));
  }
`

SpazioSociPush.propTypes = {
  label: PropTypes.string.isRequired,
}

export default SpazioSociPush
