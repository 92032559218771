import React, { Component } from "react";
import Joi from 'joi'
import { Link } from "gatsby";

export default class FormContatto extends Component {
  state = {
    contact: {
      name: "",
      email: "",
      message: "",
      consent: false,
    },
    errors: {},
    successMessage: "",
  };

  schema = Joi.object({
    name: Joi.string().required().empty().label('Inserisci nome e cognome'),
    email: Joi.string().email({ tlds: {allow: false} }).required().empty().label('Inserisci una email valida'),
    message: Joi.string().required().empty().label('Scrivi il tuo messaggio'),
    consent: Joi.boolean().required().invalid(false).label('È necessario il tuo consenso per la privacy'),
  })

  handleChange = (event) => {
    const isChecked = event.target.type === "checkbox";
    this.setState({
      contact : { ...this.state.contact, [event.target.name]: isChecked
        ? event.target.checked
        : event.target.value,}
    });
  };

  validate = () => {
    const { error } = this.schema.validate(this.state.contact, { abortEarly: false })
      return error ? Object.fromEntries(error.details.map(error => [error.context.key , error.context.label])) : {}
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate()
    this.setState({errors: errors})

    if (Object.keys(errors).length === 0) {

      
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "contatto", ...this.state.contact })
      })
      .then((data) => {
          const res = `Ciao ${this.state.contact.name}, 
                grazie per il tuo messaggio,
                ti ricontatteremo al più presto.`
              this.setState((prevState) => ({
                  ...prevState,
                  contact:  {
                    name: "",
                    email: "",
                    message: "",
                    consent: false,
                  },
                  successMessage: res,
              }));
            })
      .catch(function (err) {
          const errMsg = `Commento non inviato per un errore di sistema, riprova`
          this.setState((prevState) => ({
            ...prevState,
            successMessage: errMsg,
        }));
      });
    } 
  }

  render() {
    const {
      contact,
      errors,
      successMessage,
    } = this.state;

    return (
      <>
        <form data-netlify="true" data-netlify-honeypot="bot-field" name="contatto" action="/" onSubmit={this.handleSubmit} method='POST'>
          <label htmlFor='name'>nome e cognome</label>
          <input
            id='name'
            name='name'
            type='text'
            value={contact.name}
            onChange={this.handleChange}
          />
          <span>{errors.name}</span>
          <label htmlFor='email'>email</label>
          <input
            id='email'
            name='email'
            type='text'
            value={contact.email}
            onChange={this.handleChange}
          />
          <span>{errors.email}</span>
          <label htmlFor='message'>messaggio</label>
          <textarea
            id='message'
            name='message'
            rows='5'
            value={contact.message}
            onChange={this.handleChange}
          />
          <span>{errors.message}</span>
          <input
            type='hidden'
            name='form-name'
            value='contatto'
          />
          <div className="checkbox">
          <input
            id='consent'
            name='consent'
            type='checkbox'
            aria-label='privacy-consent'
            defaultChecked={contact.consent}
            onChange={this.handleChange}
          />
          <label className="consent" htmlFor='consent'>
            Ho letto la <Link to='/privacy-policy'>Privacy Policy </Link> e
            acconsento al trattamento dei miei dati personali da parte di A.S.D. YUAN al fine di essere ricontattato/a
          </label>
          </div>
          <span>{errors.consent}</span>
          {!successMessage && (<button type='submit' name=''>
            contattaci
          </button> )}
        {successMessage && (
          <span className="form-sent">
            {successMessage}
          </span>
        )}
        </form>
      </>
    );
  }
}

